import styled from 'styled-components';

const RootWrapper = styled.div`
  color: #666;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Oswald;
  }
`;

export default RootWrapper;
