import styled from 'styled-components';

const StyledFooter = styled.div`
  background-color: black;
  color: #999;
  font-size: 14px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
`;

const PageFooter = () => (
  <StyledFooter>
    Ordinary Man Productions &copy; {new Date().getFullYear()}
  </StyledFooter>
);

export default PageFooter;
