import { css } from 'styled-components';

const sizes = {
  giant: 1080,
  desktop: 960,
  tablet: 768,
  phone: 376
};

const media = Object.entries(sizes).reduce((acc, [sizeName, pxSize]) => {
  const emSize = pxSize / 16;

  acc[sizeName] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default media;
