import styled from 'styled-components';
import PropTypes from 'prop-types';

import Logo from './Logo';
import PageWrapper from './PageWrapper';
import PrimaryNav from './PrimaryNav';

const StyledHeader = styled.header`
  border-bottom: 1px solid #e5e5e5;

  &:after {
    clear: both;
    content: '';
    display: table;
  }
`;

const PageHeader = ({ currentPath }) => (
  <StyledHeader>
    <PageWrapper>
      <Logo />

      <PrimaryNav currentPath={currentPath} />
    </PageWrapper>
  </StyledHeader>
);

PageHeader.propTypes = {
  currentPath: PropTypes.string.isRequired
};

export default PageHeader;
