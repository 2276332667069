import PropTypes from 'prop-types';
import styled from 'styled-components';

import PrimaryNavLink from './PrimaryNavLink';
import media from '../../../utils/styling/media';

const StyledNav = styled.nav`
  ${media.tablet`
    float: right;
  `};
`;

const StyledList = styled.ul`
  margin: 20px 0;
  padding: 0;

  ${media.tablet`
    margin-top: 23px;
  `};
`;

const StyledListItem = styled.li`
  ${media.tablet`
    display: inline-block;
  `};
`;

const PrimaryNav = ({ currentPath }) => (
  <StyledNav>
    <StyledList>
      <StyledListItem>
        <PrimaryNavLink currentPath={currentPath} to="/">
          Home
        </PrimaryNavLink>
      </StyledListItem>

      <StyledListItem>
        <PrimaryNavLink
          currentPath={currentPath}
          to="/the-scottish-independence-referendum/"
        >
          The Scottish Referendum 2014
        </PrimaryNavLink>
      </StyledListItem>

      <StyledListItem>
        <PrimaryNavLink currentPath={currentPath} to="/info/">
          Info
        </PrimaryNavLink>
      </StyledListItem>

      <StyledListItem>
        <PrimaryNavLink currentPath={currentPath} to="/contact/">
          Contact
        </PrimaryNavLink>
      </StyledListItem>
    </StyledList>
  </StyledNav>
);

PrimaryNav.propTypes = {
  currentPath: PropTypes.string.isRequired
};

export default PrimaryNav;
