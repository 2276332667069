import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import media from '../../../utils/styling/media';

const DefaultLink = styled(Link)`
  color: #666;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;

  && {
    padding-top: 5px;
  }

  ${media.tablet`
    padding: 10px 15px;
  `};

  &:hover {
    color: #2ea3f2;
    text-decoration: underline;
  }
`;

const SelectedPageLink = styled(DefaultLink)`
  color: #2ea3f2;

  &:hover {
    color: #0e83c2;
  }
`;

const PrimaryNavLink = ({ children, currentPath, to }) => {
  const StyledLink = currentPath === to ? SelectedPageLink : DefaultLink;

  return <StyledLink to={to}>{children}</StyledLink>;
};

PrimaryNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  currentPath: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default PrimaryNavLink;
