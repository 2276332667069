import styled from 'styled-components';
import { Link } from 'gatsby';

import media from '../../../utils/styling/media';

const LogoLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;

  ${media.tablet`
    margin-bottom: 20px;
  `};
`;

const LogoImage = styled.img`
  height: 43px;
  width: 166px;
`;

const Logo = () => (
  <LogoLink to="/">
    <LogoImage src="/media/nupateer-logo@2x.png" />
  </LogoLink>
);

export default Logo;
