import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import { Link } from 'gatsby';

const CookiesPolicyLink = styled(Link)`
  color: #34c7f0;
`;

const CookieBanner = () => (
  <CookieConsent
    style={{ background: '#2B373B' }}
    buttonStyle={{
      background: '#14a7d0',
      color: '#fff',
      padding: '0 40px',
      fontWeight: 'bold',
      cursor: 'pointer'
    }}
    buttonText="Got it!"
  >
    This website uses cookies to enhance the user experience.{' '}
    <CookiesPolicyLink to="/cookies-policy">Learn more</CookiesPolicyLink>
  </CookieConsent>
);

export default CookieBanner;
