import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import GlobalSiteStyles from './components/GlobalSiteStyles';
import PageContent from './components/PageContent';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';
import PageWrapper from './components/PageWrapper';
import RootHelmet from './components/RootHelmet';
import RootWrapper from './components/RootWrapper';
import CookieBanner from './components/CookieBanner';

const GlobalStyles = createGlobalStyle`
  .alignleft {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .alignright {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .et_post_video {
    & + & {
      margin-top: 30px;
    }
  }

  .fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
    padding-top: 56.2963%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
`;

const MasterLayout = ({ children, location }) => (
  <RootWrapper>
    <GlobalSiteStyles />
    <GlobalStyles />

    <RootHelmet />

    <PageHeader currentPath={location.pathname} />

    <PageContent>
      <PageWrapper>{children}</PageWrapper>
    </PageContent>

    <PageFooter />

    <CookieBanner />
  </RootWrapper>
);

MasterLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default MasterLayout;
