import 'sanitize.css';
import { createGlobalStyle } from 'styled-components';

const GlobalSiteStyles = createGlobalStyle`
  body {
    line-height: 1.6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
  }

  h1 { font-size: 30px; }
  h2 { font-size: 26px; }
  h3 { font-size: 22px; }
  h4 { font-size: 18px; }
  h5 { font-size: 16px; }
  h6 { font-size: 14px; }

  .post-content-wrapper {
    padding-top: 20px;
    padding-bottom: 30px;

    .post-content {
      img {
        max-width: 100%;
      }
    }
  }

  a {
    color: #1E93e2;
    text-decoration: none;

    &:hover {
      color: #2EA3F2;
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  .scottishRootLink {
    color: #b70000 !important;
    display: block;
    text-align: center;
    position: absolute;
    width: 200px !important;
    height: 200px !important;
    font-size: 20px !important;
    top: 295px;
    left: 300px;

    &:hover {
      text-decoration: underline;
    }
  }

  .scotReferendumWrapperOuter {
    position: relative;

    @media only screen and (max-width: 980px) {
      height: 580px;
    }

    @media only screen and (max-width: 767px) {
      height: 420px;
    }

    @media only screen and (max-width: 479px) {
      height: 300px
    }
  }

  .scotReferendumWrapper {
    background-image: url(/media/Independence-circle.png);
    height: 800px;
    margin: 10px auto 20px;
    position: relative;
    width: 800px;

    @media only screen and (max-width: 980px) {
      -webkit-transform: scale(0.7) !important;
      -webkit-transform-origin: 0 0 !important;
      left: -280px;
      margin-left: 50%;
      position: absolute;
    }

    @media only screen and (max-width: 767px) {
        -webkit-transform: scale(0.5) !important;
        -webkit-transform-origin: 0 0 !important;
        left: -200px;
    }

    @media only screen and (max-width: 479px) {
      -webkit-transform: scale(0.35) !important;
      -webkit-transform-origin: 0 0 !important;
      left: -140px;
    }
  
    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      font-size: 19px !important;
      height: 120px;
      left: 0;
      list-style-type: none;
      position: absolute;
      top: 0;
      width: 120px;

      a {
        display: block;
        height: 100%;
        width: 100%;
        color: white !important;
        text-align: center;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .scotReferendumWrapper li {
    /* Ad 1 */
    &.menu-item-122 {
      left: 340px;
      top: 50px;
    }

    /* Ad 2 */
    &.menu-item-124 {
      left: 525px;
      top: 100px;
    }

    /* Ad 3 */
    &.menu-item-123 {
      left: 640px;
      top: 260px;
    }

    /* Ad 4 */
    &.menu-item-121 {
      left: 640px;
      top: 440px;
    }

    /* Ad 5 */
    &.menu-item-120 {
      left: 525px;
      top: 590px;
    }

    /* Ad 6 */
    &.menu-item-119 {
      left: 340px;
      top: 655px;
    }

    /* Ad 7 */
    &.menu-item-118 {
      left: 150px;
      top: 620px;
    }

    /* Ad 8 */
    &.menu-item-117 {
      left: 40px;
      top: 450px; 
    }

    /* Ad 9 */
    &.menu-item-116 {
      left: 40px;
      top: 245px;
    }

    /* Ad 10 */
    &.menu-item-115 {
      left: 155px;
      top: 115px;
    }
  }
`;

export default GlobalSiteStyles;
